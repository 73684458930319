import React from "react";
import "../assets/contact-us/contactus.styles.scss";
import { graphql } from "gatsby";
import Layout from "../components/Layout";
import ContactBox from "../components/ContactBox";
import SocialConnect from "../components/ConnectSocial";
import { ImWhatsapp } from "@react-icons/all-files/im/ImWhatsapp";
import shape from "../assets/shapes/footer-shape.png";

const About = ({ data }) => {
  const pp = data.strapiContactInfo.phone.replace(/[^0-9 ]/g, "");
  return (
    <Layout title={"Contact Us"}>
      <div className="about">
        <div className="ssbody wrapper_max ">
          <div className="content" style={{ textAlign: "center" }}>
            <div className="ssbody_new">
              <div style={{ marginRight: 10, marginLeft: 10 }}>
                <div className="newTitle">
                  <h1>
                    Have a Question ?
                    <br />
                    Let’s Get in Touch with us 👋
                  </h1>
                </div>
                <a
                  className="nolink"
                  href={
                    "https://api.whatsapp.com/send?phone=" + pp + "&text=Hello"
                  }
                  target="_blank"
                  rel="noreferrer"
                >
                  <div className="whatsapp">
                    <div className="ico">
                      <ImWhatsapp />
                    </div>
                    Contact us on Whatsapp
                  </div>
                </a>
                <hr />
                <p>
                  Fill up the Form and ou team will get back to within 24 hrs
                </p>
                <ContactBox />
              </div>
              <div className="contact-side">
                <img src={shape} className="shape-buttonv3-max" />
                {/* <img src={shapes} style={{position:'absolute', top:0, right:0,height:'100px'}}/> */}
                <p>Location</p>
                <p>
                  Plot No. B-357A, Second Floor, New Ashok Nagar Metro Station
                  Ext Phase I, Mayur Vihar Phase 1, Metro Pillar 157, New Delhi,
                  110096
                </p>
                <br />
                <p>Working Hours</p>
                <p>
                  Monday To Sunday <br /> 9:00 AM to 8:00 PM <br />
                  Our Support Team is available 24Hrs
                </p>
                <br />
                <p>Contact Us</p>
                <p>+91 95604 50376</p>
                <p>rajan@pestgogo.com</p>
              </div>
            </div>

            <div style={{ width: "100%", height: "400px" }}>
              <iframe
                src="https://maps.google.com/maps?q=Pestgogo&amp;t=&amp;z=13&amp;ie=UTF8&amp;iwloc=&amp;output=embed"
                width={"100%"}
                height={400}
                frameBorder={0}
                allowfullscreen
              ></iframe>
            </div>
            <SocialConnect />
          </div>
        </div>
      </div>
    </Layout>
  );
};

export const query = graphql`
  {
    strapiFaq {
      body
    }
    strapiContactInfo {
      phone
    }
  }
`;

export default About;
