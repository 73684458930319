import React, { useState } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import axios from "axios";
import Buttonv3 from "./buttons/buttonv3";

export default function ContactBox() {
  const [captcha, setcaptcha] = useState(0);
  const [error, setError] = useState("");
  const [confirm, setConfirm] = useState("");

  const [name, setname] = useState("");
  const [phone, setphone] = useState("");
  const [email, setemail] = useState("");
  const [body, setbody] = useState("");

  function onChange(value) {
    setError("");
    setcaptcha(1);
  }

  function sub() {
    if (name == "" || phone == "" || email == "" || body == "") {
      setError("Please fill all detail before submitting");
    } else {
      axios
        .post("https://dashboard.pestgogo.com/support-forms", {
          name: name,
          number: phone,
          email: email,
          body: body,
        })
        .then((x) => {
          setConfirm("Submit Successfully");
        })
        .catch((x) => {
          setError("Request Failed please contact us on Whatsapp");
        });
    }
  }

  return (
    <div>
      <div className="cc">
        <div className="rr">
          <div className="row input-container">
            <div className="col-xs-12">
              <div className="styled-input">
                <input
                  type="text"
                  value={name}
                  onChange={(x) => setname(x.target.value)}
                  required
                />
                <label>Name</label>
              </div>
            </div>
            <div className="col-md-6 col-sm-12">
              <div className="styled-input">
                <input
                  type="text"
                  value={email}
                  onChange={(x) => setemail(x.target.value)}
                  required
                />
                <label>Email</label>
              </div>
            </div>
            <div className="col-md-6 col-sm-12">
              <div className="styled-input">
                <input
                  type="text"
                  value={phone}
                  onChange={(x) => setphone(x.target.value)}
                  required
                />
                <label>Phone Number</label>
              </div>
            </div>
            <div className="col-xs-12">
              <div className="styled-input">
                <textarea
                  required
                  value={body}
                  onChange={(x) => setbody(x.target.value)}
                ></textarea>
                <label>Message</label>
              </div>
            </div>

            <ReCAPTCHA
              style={{}}
              className="captc"
              sitekey="6LfsXtIbAAAAAH3tgV6kq0XxDJELe9D6OLQ6ZtDu"
              onChange={onChange}
              onExpired={() => setcaptcha(0)}
              onErrored={() => setcaptcha(0)}
            />

            <div className="col-xs-12">

              {/* <div
                className="btn-lrg submit-btn"
                style={{ opacity: captcha == 1 ? "100%" : "50%" }}
               
              >
                Send Message
              </div> */}

                <Buttonv3 
                onClick={() => {
                  captcha == 0
                    ? setError("Confirm captcha before submitting")
                    : sub();
                }}
                 styles={{ opacity: captcha == 1 ? "100%" : "50%" }} title="Send Message"/>

            </div>
            <p style={{ color: "green" }}>{confirm}</p>
            <p style={{ color: "red" }}>{error}</p>
          </div>
        </div>
      </div>
    </div>
  );
}
