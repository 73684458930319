import React from "react";
import "./buttonv3.scss";
import shape from "../../assets/button/shape.png";
import { Link } from "gatsby";

export default function Buttonv3({ title, styles, onClick, toLink }) {
  return (
    <div style={{ width: "fit-content" }}>
      <Link to={toLink} style={{ textDecoration: "none" }}>
        <div onClick={onClick} className="RequestQuotes" style={styles}>
          <img src={shape} className="shape-buttonv3" />
          {title}
        </div>
      </Link>
    </div>
  );
}
